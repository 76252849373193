/* You can add global styles to this file, and also import other style files */
body{
  font-family: 'Roboto', sans-serif !important;
}
h6 {
  font-weight: 500 !important;
}
.labelCls {
  display: block;
  font-size: 12px;
  color: #535353;
}
.inputCls {
  width: 100%;
  border: 1px solid #c5c6cb;
  height: 35px;
  line-height: 35px;
  border-radius: 4px;
  text-indent: 5px;
  font-size: 12px;
}
button.calendar,
button.calendar:active {
  width: 2.75rem;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=") !important;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: center;
}
.btn-outline-secondary {
  color: #ccc;
  border-color: #ccc;
}
.calendarCls.form-control {
  font-size: 12px;
}
input:focus,
select:focus,
.form-control:focus {
  outline: none;
  border: 1px solid #ccc;
  box-shadow: none;
}
.btn-outline-secondary:hover,
.btn-outline-secondary:active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  background-color: transparent;
  border-color: #ccc;
  box-shadow: none;
}
.helpTxt {
  font-size: 10px;
}
.smallHeadingCls {
  width: 100%;
  margin: 15px 0 0 15px;
  color: #0606a2;
}
.mandatoryCls {
  color: red;
  vertical-align: top;
}
.selectError {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.selectNoError {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: black;
}
.selectCls {
  width: 100%;
  height: 35px;
  line-height: 35px;
  border: 1px solid #c5c6cb;
  border-radius: 4px;
  text-indent: 5px;
  font-size: 12px;
}

.headingBgCls {
  color: #fff;
  background: #05538c;
  padding: 5px 10px;
}
.btn {
  font-size: 13px;
}
.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active {
  background: #d66d3c;
  border: 1px solid #d66d3c;
  box-shadow: none;
  outline: none;
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem #d66d3c;
}
.tableCls {
  width: 100%;
}
.tableCls th {
  padding: 5px;
  color: #fff;
  background: #996515;
  text-align: center;
  font-weight: normal;
  border: 1px solid #ccc;
}
.radioCls {
  vertical-align: text-bottom;
}
.tableCls td {
  padding: 5px;
  border: 1px solid #ccc;
}
.tableCls td .form-control {
  font-size: 12px;
}
.inputCls.is-invalid,
.selectCls.is-invalid,
.text-areaCls.is-invalid {
  border: 1px solid red;
}

.input-group-append .btn,
.input-group-prepend .btn {
  z-index: 5;
}
.bodyBg {
  /* background-color: #ededed; */
  background-color:white;
}
.breadcrumbContainer {
  border: 1px solid #dbdbdb;
  border-radius: 8px 8px 0 0;
  padding: 20px;
  background-color: #fff;
}
.breadcrumbCls {
  background: #f5f8ff;
  border: 1px solid #e2e2e2;
  line-height: 31px;
  padding: 0 10px;
  color: #333;
  font-size: 12px;
}
.activePage {
  color: #a8a8a8;
}
.mainCls {
  position: absolute;
  top: 75px;
}
.mainPageContainer {
  border: 1px solid #dbdbdb;
  padding: 20px;
  background-color: #fff;
  margin-top: 3px;
}
.nav-tabs .nav-link {
  width: 50%;
  text-align: center;
  color: #333;
  font-weight: bold;
  font-size: 14px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: none;
  color: #333;
  border: none;
  border-right: 1px solid #c5c6cb;
  border-radius: 0;
  border-bottom: 4px solid #E46B2E;
}
.nav-tabs {
  border: 1px solid #c5c6cb;
  border-radius: 8px 8px 0px 0px;
}
.nav-tabs .nav-link {
  border-right: 1px solid #c5c6cb;
  border-radius: 0;
}
.nav-tabs .nav-link:last-child {
  border-right: 0;
}
.tab-content {
  border: 1px solid #c5c6cb;
  padding: 15px;
}
.subHeadingCls {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}
.hrCls {
  border-top: 1px solid #c5c6cb;
}
.greenBtnCls {
  background-color: #d66d3c;
  line-height: 38px;
  border-radius: 4px;
  color: #fff;
  border: none;
  padding: 0 15px;
  min-width: 100px;
}
.bntResetCls {
  background: #fff;
  color: #d66d3c;
  border: 1.5px solid #d66d3c;
}
.btnHolder {
  height: 40px;
}
.textareaCls {
  border: 1px solid #c5c6cb;
  height: 35px;
  line-height: 35px;
  border-radius: 4px;
  text-indent: 5px;
  font-size: 12px;
  resize: none;
  width: 100%;
}
.checkboxCls {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.checkBoxCont .labelCls {
  display: inline-block;
}
.clearCls {
  background: url("assets/clear-icon.png") 5px 8px no-repeat;
  height: 20px;
  width: 20px;
  display: inline-block;
  cursor: pointer;
}
.whiteBtnCls {
  line-height: 36px;
  border-radius: 4px;
  color: #535353;
  border: 1px solid #4bb43a;
  padding: 0 15px;
  min-width: 100px;
  background: #fff;
}
/*dashboard css*/

.dashboardBox {
  background: #05538c;
  color: #fff;
  padding: 15px;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}
.dashboardGrid tr {
  cursor: pointer;
}
.documentsList ul {
  padding: 0;
}
.documentsList ul li {
  list-style: none;
  margin-bottom: 5px;
}
.documentsList ul li a:hover {
  cursor: pointer;
  text-decoration: underline;
}
.dashboardPage {
  margin-left: 30px;
  margin-right: 46px;
}
.welcomeContainer.spacerClass {
  padding: 15px 65px 30px !important;
}
.totalSubmitted {
  background: url('assets/registered.png') no-repeat 96% #00BEFE;
}
.openWithinSLA {
  background: url("assets/within-sla.png") no-repeat 96% #C98D03;
}
.openBeyondSLA {
  background: url("assets/beyond-sla.png") no-repeat 96% #FF2A7F;
}
.closedWithinSLA {
  background: url("assets/within-sla.png") no-repeat 96% green;
}
.closedBeyondSLA {
  background: url("assets/beyond-sla.png") no-repeat 96% #952AFF;
}
.revertBack {
  background: url("assets/revert-back.png") no-repeat 96% #3a9be5;
}
.recommended {
  background: url("assets/recommended.png") no-repeat 96% #109551;
}
.dashboardBox .count {
  font-size: 30px;
  line-height: 30px;
}
.requestList {
  padding: 20px 35px;
  background: #f0f2f5;
  border: 1px solid #e2e2e2;
}
.dashboardGrid {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
}
.dashboardGrid th {
  padding: 10px;
  color: #373738;
}
.dashboardGrid td {
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  background: #fff;
  padding: 10px;
  color: #535353;
}
.dashboardGrid td:first-child {
  border-left: 1px solid #d3d3d3;
  border-radius: 6px 0 0 6px;
}
.dashboardGrid td:last-child {
  border-right: 1px solid #d3d3d3;
  border-radius: 0 6px 6px 0;
}
.dashboardGrid tr:hover td, .dashboardGrid tr.selected td {
  background-color: #996515;
  border-top: 1px solid #996515;
  border-bottom: 1px solid #996515;
  color: #fff;
}
.dashboardGrid tr:hover td:first-child {
  border-left: 1px solid #996515;
}
.dashboardGrid tr:hover td:last-child {
  border-right: 1px solid #996515;
}
.certificateDetails {
  border: 1px solid #c5c6cb;
  padding: 20px 0;
  background: #fff;
}
.certificateDetails .title {
  border-bottom: 1px solid #c5c6cb;
  padding: 0 35px 20px 35px;
  font-weight: 500;
}
.fieldHolder .labelCls {
  color: #535353;
  font-weight: bold;
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
.certificateInfo, app-common-form-view .certificateInfo:last-child {
  margin: 0 35px 20px 35px;
  border-bottom: 1px solid #c5c6cb;
  padding-bottom: 20px;
}
.certificateInfo:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.fieldHolder .valueCls {
  color: #535353;
  display: inline-block;
  width: 44%;
  overflow-wrap: break-word;
}
.fieldHolder .valueCls a {
  color: #258d15;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}
.fieldHolder .valueCls a:hover {
  color: #3699d6;
}
.fieldHolder .numberCls {
  background: #996515;
  color: #fff;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  vertical-align: top;
}
.blueBtnCls {
  background-color: #fff;
  line-height: 38px;
  border-radius: 4px;
  color: #996515;
  border: 1.5px solid #996515;
  padding: 0 15px;
  min-width: 100px;
}
.page-item.active .page-link,
.page-item.active .page-link a:focus {
  background-color: #996515 !important;
  border: 1px solid #996515;
  box-shadow: none;
}
.download {
  background: url("assets/download-icon.png") no-repeat;
  height: 16px;
  display: inline-block;
  width: 16px;
  cursor: pointer;
}
.searchInput {
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 17px;
  width: 270px;
  height: 32px;
  text-indent: 5px;
}
.modal-body p {
  font-size: 14px;
}

/* new for sliderw on home page */

.video_carousel .swiper-button-next {
  background-image: url("./assets/arrow_right.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 14px;
  /* background-color: #0697ac; */
  border-radius: 2px;
  border: 2px solid #e0d9d9;
  background-color: white;
  height: 30px;
  width: 30px;
}
.video_carousel .swiper-button-next:after {
  display: none;
}
.video_carousel .swiper-button-prev {
  background-image: url("./assets/arrow_left.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 14px;
  /* background-color: #0697ac; */
  border-radius: 2px;
  border: 2px solid #e0d9d9;
  background-color: white;
  height: 30px;
  width: 30px;
}
.video_carousel .swiper-button-prev:after {
  display: none;
}
.nopadding {
  padding: 0;
}
.nomargin {
  margin: 0;
}
.note{
  font: bold;
  color: blue;
}
/* 
.test_full_slider .carousel-control-next-icon {
  right: 153px;
  position: absolute;
  top: auto;
  bottom: -21px;
  background-image: url("./assets/arrow_right.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 16px;
   
}

.test_full_slider .carousel-control-prev-icon {
  left: 150px;
  position: absolute;
  top: auto;
  bottom: -21px;
  background-image: url("./assets/arrow_left.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 16px;
} */
.btn_login_cls{
  position: absolute;
  top: 10px;
  right: 30%;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  background: #0632A3 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  text-align: left;
  font-size: 14px;
  padding: 5px 30px;
  font-weight:300;
  z-index: 1000;
}

.dots .carousel-indicators {
  margin-bottom: -20px;
}
 
.dots .carousel-indicators  li{
  height: 15px;
  width: 15px;
  background-color:rgb(165, 155, 155);
  border-radius: 50%;
  display: inline-block;
  /* color:orange; */
}
 
.dots .carousel-indicators .active{
   background-color:orange;
}

/* @font-face {
  font-family: Helvetica Neue;
  src: url('assets/font/Helvetica Neue Medium Extended.ttf');
} */


.video_slider .swiper-button-prev{
  position: absolute;
  bottom: 0px;
  right: 34px;
  left: auto;
  top: auto;
}


.video_slider .swiper-button-next{
  right: 0;
  left: auto;
  bottom: 0px;
  top: auto;
}
.main_slider_bg_img .carousel-indicators{
  display:none;
}
.custom-file .fa-search{
  color: #9e9e9e;
    padding-right: 3px;
}
.add-new i, .fa-trash{
  padding-right: 4px;
}
.successMsg {
  color: #4bb43a;
  font-weight: bold;
}
.annexureLabel {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
.file-upload-cls input[type=file]::file-selector-button {
  border: 1px solid #05538c;
  padding: .375rem .75rem;
  border-radius: .25em;
  background-color: #05538c;
  color: #fff;
}
.modal-body .invalid-feedback {
  font-size: 12px;
}
.highlight {
  background-color: yellow;
}
/* input[type=file]::file-selector-button:hover {
  background-color: #81ecec;
  border: 2px solid #00cec9;
} */
.is-invalid .multiselect-dropdown .dropdown-btn {
  border: 1px solid red !important;
}

/* .is-invalid .multiselect-dropdown[_ngcontent-leg-c151] .dropdown-btn[_ngcontent-leg-c151] {
  border: 1px solid red !important;
} */

.testimonial-slide {
  position: relative;
}
.testimonial-slide .owl-theme .owl-nav {
  position: absolute;
  top: -50px;
  /* bottom: 0; */
  top: -50px;
  right: 25px;
  /* margin: 14px; */
  left: auto;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.testimonial-slide .owl-theme  .owl-nav div {
  border: none;
  height: 35px;
  width: 35px;
  border: #fff solid 1px;
  color: #fff;
  background-color: #607D8B;
}

.testimonial-slide .owl-theme  .owl-nav div:hover {
  color: #033b4a;
  background-color: #607D8B;
  border: #fff solid 1px;
}

.testimonial-slide .owl-theme  .owl-nav div.owl-next,
.testimonial-slide .owl-theme  .owl-nav div.owl-prev,
.defalut-view-testimonial .testimonial-slide .owl-theme  .owl-nav div.owl-next,
.defalut-view-testimonial .testimonial-slide .owl-theme  .owl-nav div.owl-prev{
  bottom: 0;
  top: -50px;
  right: 49px;
  left: auto;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.testimonial-slide .owl-theme  .owl-nav div.owl-next:before {
  content: "\e628";
  font-size: 14px;
  color: #fff;

}
.testimonial-slide .owl-theme .owl-nav div.owl-prev:before {
  content: "\e629";
  font-size: 14px;
  color: #fff;
}
.testimonial-slide .owl-theme .owl-nav div.owl-next{
  margin-right: -25px;
}
.team-slide { position: relative;}
.team-slide .owl-theme  .owl-nav div.owl-next{ right: -21px; }
.team-slide .owl-theme  .owl-nav div.owl-prev{
    left: -21px;
 }

.team-slide .owl-theme  .owl-nav div {
    position: absolute;
    top: 44%;
    height: 32px;
    width: 32px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #033b4a;
    text-align: center;
}

.team-slide .owl-theme  .owl-nav div:hover {
  color: #033b4a;
  background-color: #fff;
  border: #033b4a solid 1px;
}
.team-slide .owl-theme  .owl-nav div.owl-prev:before {
  content: "\e64a";
  color: #033b4a;

}

.team-slide .owl-theme  .owl-nav div.owl-prev:before, .team-slide .owl-theme  .owl-nav div.owl-next:before,
.testimonial-slide .owl-theme  .owl-nav div.owl-prev:before, .testimonial-slide .owl-theme  .owl-nav div.owl-next:before {
  font-family: themify;
  font-size: 14px;
  line-height: 25px;
  font-weight: 600;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.team-slide .owl-theme  .owl-nav div.owl-next:before {
  content: "\e649";
  color: #033b4a;
}
.team-slide.video-slide .owl-theme .owl-nav div {
  border: none;
  border-radius: 0;
  height: 50px;
  width: 40px;
  background: #333;
}
.team-slide.video-slide .owl-theme .owl-nav div.owl-next:before,
 .team-slide.video-slide .owl-theme .owl-nav div.owl-prev:before {
  line-height: 40px;
  font-size: 25px;
}
.team-slide.video-slide .owl-theme .owl-nav div.owl-next {
  right: 10px;
}
.team-slide.video-slide .owl-theme .owl-nav div.owl-prev {
  left: 10px;
}
.team-slide.video-slide .owl-theme .owl-nav div:before, .team-slide.video-slide .owl-theme .owl-nav div:hover {
  color: #fff;
}
.logo-text{
  font-size: 24px;
  display: inline-block;
  padding-left: 10px;
  vertical-align: middle;
  letter-spacing: 2px;
}
.logo-text .english-text {
  font-family: 'Roboto-Regular';
}
.logo-text .telugu-text {
  font-family: 'Mandali';
}
.captcha-container {
  box-shadow: none !important;
  width: 450px !important;
  text-align: center;
}
.errMsg{
  color: red;
  text-align: center;
  margin-left: 80px;
}
ng-multiselect-dropdown .dropdown-list {
  position: static !important;
}
ng-multiselect-dropdown li.no-filtered-data h5, ng-multiselect-dropdown li.no-data h5 {
  font-size: 14px;
}

.scrollToTop {
  height: 35px;
  width: 35px;
  background-color: #DB2927;
  position: fixed;
  z-index: 100;
  bottom: 60px;
  right: 40px;
  border-radius: 4px;
  display: none;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 28px;
  cursor: pointer;
}

.align-top {
  vertical-align: top;
}